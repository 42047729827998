<template>
  <div 
    class="product"
    v-if="product"
  >
    <v-form
      @submit.prevent="onSubmit"
      v-model="valid"
    >
      <v-container>
        <div class="product-info">
          <div class="product-title">
            <v-text-field 
              label="Product title"
              v-model="form.title"
            />
          </div>
          <div class="product-description">
            <v-text-field 
              label="Product description"
              v-model="form.description"
            />
          </div>
        </div>
        <div class="product-main-price">
          <v-text-field 
            type="number"
            label="Product price"
            v-model="form.price"
          />
        </div>
        <div class="d-flex">
          <v-spacer />
          <v-btn 
            type="submit"
            :loading="inUpdateProcess"
            color="success"
          >
            Save
          </v-btn>
        </div>
        <div class="product-prices pt-10">
          <v-card class="product-countries-price" v-if="product[1].info.prices">
            <v-card-title>
              <div>Prices</div>
              <v-spacer />
              <v-btn 
                @click="sync"
                color="primary">
                Sync
              </v-btn>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items-per-page="-1"
              :sort-by="['price']"
              :items="items"
            >
              <template v-slot:item.countries="{ item }">
                {{ item.countries.map(({ countryName }) => countryName).join(', ') }}
              </template>
              <template v-slot:item.price="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.price"
                  @save="onSavePrice"
                  @cancel="() => {}"
                  @open="() => {}"
                  @close="() => {}"
                >
                  {{ parseFloat(item.price).toFixed(2) }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="item.price"
                      label="Edit"
                      :hint="`${getPrice(item.exRate, item.currency).toFixed(2)}`"
                      single-line
                      type="number"
                    />
                    <div class="d-flex">
                      <v-spacer />
                      <v-btn
                        @click="e => onSubmit(e, {
                          [`info.prices.${item.currency}.amount`]: item.price
                        })"
                        color="success"
                        :loading="inUpdateProcess"
                        class="my-2"
                      >
                        Save
                      </v-btn>
                    </div>
                  </template>
                </v-edit-dialog>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </v-container>
    </v-form>
  </div>
</template>
<script>
import { updateDocInCollection } from '@/fb'

export default {
  name: 'DashboardProduct',
  data: () => ({
    product: null,
    correctionRates: null,
    exchangeRates: null,
    inUpdateProcess: false,
    countries: [],
    form: {
      title: null,
      description: null,
      price: null
    },
    valid: false,
    items: [],
    headers: [
      {
        text: 'Countries',
        value: 'countries'
      },
      {
        text: 'Currency',
        value: 'currency'
      },
      {
        text: 'Price',
        value: 'price'
      }
    ]
  }),
  async created() {
    this.init()
  },
  methods: {
    async onSubmit(e, targetField = null) {
      this.inUpdateProcess = true

      let data = {}

      if (!targetField) {
        Object.keys(this.form).forEach(key => {
          data[`info.${key}`] = this.form[key]
        })
      } else {
        data = targetField
      }

      const updated = await updateDocInCollection('products', this.$route.params.productId, data)

      this.product = null

      this.inUpdateProcess = false


      this.$nextTick(() => {
        this.product = updated
      })
    },
    onSavePrice() {
    },
    getPrice(exRate, csymbol) {
      const { correctionRate = 0.56 } = (this.correctionRates[csymbol] || {})
      return this.product[1].info.price / parseFloat(exRate) * parseFloat(correctionRate)
    },
    async sync() {
      const result = Object.entries(this.exchangeRates).reduce((acc, item) => {
        const [ csymbol, data ] = item
        const { crate: exRate } = data

        const amount = this.getPrice(exRate, csymbol)
        console.log(csymbol, amount)

        acc[csymbol] =  {
          amount
        }
        return acc
      }, {})

      const { productId } = this.$route.params

      await updateDocInCollection('products', productId, {
        'info.prices': result
      })
      
      console.log('Updated!')
    },
    async fetchCountries() {
      const url = '/countries.json'

      try {
        const response = await fetch(url)
        const json = await response.json()
        this.countries = json
      } catch(err) {
        console.log(err)
      }
    },
    async fetchExhangeRates() {
      const url = '/exchange-rates.json'

      try {
        const response = await fetch(url)
        const json = await response.json()
        const result = {}

        for (let key in json) {
          const exRate = json[key]
          const { csymbol: currencyCode } = exRate
          exRate.countries = this.countries.filter(country => country.currencyCode === currencyCode) || null
          result[key] = exRate
        }

        this.exchangeRates = result
      } catch(err) {
        console.log(err)
      }
    },
    async fetchCorrectionRates() {
      const url = '/correction-rates.json'

      try {
        const response = await fetch(url)
        const json = await response.json()
        this.correctionRates = json
      } catch(err) {
        console.log(err)
      }
    },
    async init() {
      await this.fetchCountries()
      await this.fetchExhangeRates()
      await this.fetchCorrectionRates()
      await this.fetchProduct()
      await this.initForm()

      const prices = this.product[1].info.prices || []
      this.items = Object.keys(prices).map(csymbol => {

        const countries = this.exchangeRates[csymbol].countries
        const { crate: exRate } = this.exchangeRates[csymbol]
        const currency = csymbol
        const price = prices[csymbol].amount

        return {
          exRate,
          countries,
          currency,
          price
        }
      })
    },
    async fetchProduct() {
      this.product = await this.$store.dispatch('getProduct', this.$route.params.productId)
    },
    async initForm() {
      const { info } = this.product[1]

      Object.keys(this.form).forEach(key => {
        this.form[key] = info[key] || null
      })
    }
  }
}
</script>